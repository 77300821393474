<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top  header-transparent ">
    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
        <h1><a href="/">niostack studio</a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="/">Home</a></li>
          <li><a class="nav-link" href="/ai.html">AI Chat</a></li>
          <li><a class="nav-link" href="//tikmatrix.com">TikTok Matrix</a></li>


        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div
          class="col-lg-10 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up">
          <div>
            <h1>niostack studio</h1>
            <h2>
              niostack工作室专注于AI领域，自动化办公领域软件开发！
            </h2>
            <a href="//t.me/+iGhozoBfAbI5YmE1" class="download-btn"><i class="bx bxl-telegram"></i>TG Group</a>
            <a href="//so.niostack.com" class="download-btn"><i class="bx bxl-google"></i>Google Search</a>
            <a href="//chat.niostack.com" class="download-btn"><i class="bx bx-chat"></i>AI Chat</a>
            <a href="//tikmatrix.com" class="download-btn"><i class="bx bxl-tiktok"></i>TikTok Matrix</a>
          </div>
        </div>

      </div>
    </div>

  </section><!-- End Hero -->

  <FooterPage />
</template>


<script>
import FooterPage from './components/FooterPage.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HomePage',
  components: {
    FooterPage
  }
})

</script>